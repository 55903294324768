import React, { Component } from "react";
import styled from "styled-components/dist/styled-components.browser.es.js";
import { lighten, rgba } from "polished/dist/polished.es.js";
import yup from "yup";

import Success from "./success";
import pmLogo from "./assets/pm_logo.svg";

const isOdd = num => {
  return num % 2;
};

const sortByProperty = property => {
  var sortOrder = 1;
  if (property[0] === "-") {
    sortOrder = -1;
    property = property.substr(1);
  }
  return function(a, b) {
    var result = a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
    return result * sortOrder;
  };
};

const PRODUCTION = process.env.NODE_ENV === "production";

const Title = styled.h1`
  width: 100%;
  text-align: center;
  margin: 24px 0 24px 0;
  color: ${props =>
    props.customTheme && props.customTheme.headerTextColor ? props.customTheme.headerTextColor : "#fff"};
`;

export const FormContainer = styled.form`
  position: relative;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  max-width: 768px;
  margin: 0 auto;
  padding: 9px;
  border-radius: 0;
  box-sizing: border-box;
  background: ${props => (props.customTheme ? props.customTheme.formBgColor : "transparent")};
  color: ${props => (props.customTheme ? props.customTheme.formTextColor : props.theme === "light" ? "#666" : "#fff")};
  font-family: "Proxima Nova", Helvetica, arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  overflow: hidden;
  opacity: ${props => (props.apiDataLoaded == true ? "1" : "0")};
  transition: opacity 225ms ease-in-out;

  * {
    font-family: "Proxima Nova";
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
  }
`;

export const FormInput = styled.input`
  height: 48px;
  width: 100%;
  line-height: 48px;
  padding: 0 18px;
  margin-bottom: 6px;
  border: 0;
  border-radius: 0;
  font-size: 18px;
  background: ${props =>
    props.customTheme ? props.customTheme.inputBgColor : props.theme === "light" ? "#fff" : "#242525"};
  color: ${props =>
    props.customTheme && !props.error
      ? props.customTheme.inputTextColor
      : props.error
        ? "red"
        : props.theme === "light"
          ? "#666"
          : "#fff"};

  &:focus {
    outline: 0;
  }

  &::placeholder {
    color: ${props =>
      props.customTheme && !props.error
        ? props.customTheme.inputTextColor
        : props.error
          ? "red"
          : props.theme === "light"
            ? "#999"
            : "#fff"};
  }

  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;

export const FormTextarea = styled.textarea`
  min-height: 150px;
  width: 100%;
  padding: 15px 18px 15px 18px;
  margin-bottom: 6px;
  border: 0;
  border-radius: 0;
  font-size: 18px;
  background: ${props =>
    props.customTheme ? props.customTheme.inputBgColor : props.theme === "light" ? "#fff" : "#242525"};
  color: ${props =>
    props.customTheme && !props.error
      ? props.customTheme.inputTextColor
      : props.error
        ? "red"
        : props.theme === "light"
          ? "#666"
          : "#fff"};

  &:focus {
    outline: 0;
  }

  &::placeholder {
    color: ${props =>
      props.customTheme && !props.error
        ? props.customTheme.inputTextColor
        : props.error
          ? "red"
          : props.theme === "light"
            ? "#999"
            : "#fff"};
  }

  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;

export const Consents = styled.ul`
  position: relative;
  width: 100%;
  padding: 18px;
  margin: 0 0 6px 0;
  list-style: none;
  background: ${props =>
    props.customTheme ? props.customTheme.inputBgColor : props.theme === "light" ? "#fff" : "#242525"};
  color: ${props => (props.customTheme ? props.customTheme.inputTextColor : props.theme !== "light" ? "#fff" : "#666")};
`;

export const ConsentsHeading = styled.h4`
  font-size: 18px;
  margin: 0 0 12px 0;
`;

export const Consent = styled.li`
  width: 100%;
  margin: 12px 0;
`;

export const ConsentLabel = styled.label`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const ConsentInput = styled.input`
  position: absolute;
  left: -9999px;
`;

export const ConsentCheck = styled.span`
  position: relative;
  min-width: 20px;
  min-height: 20px;
  margin-right: 18px;
  background: ${props =>
    props.customTheme && props.customTheme.checkboxBgColor
      ? props.customTheme.checkboxBgColor
      : props.theme === "light"
        ? "#eee"
        : "#fff"};

  &:after {
    display: ${props => (props.checked ? "block" : "none")};
    content: "";
    position: absolute;
    left: calc(50% + 3px);
    top: calc(50% - 4px);
    width: 20px;
    height: 12px;
    border-style: solid;
    border-color: ${props =>
      props.customTheme && props.customTheme.checkboxCheckColor ? props.customTheme.checkboxCheckColor : "#b78435"};
    border-width: 0px 0px 3px 3px;
    transform: translate3d(-50%, -50%, 0) rotate(-45deg);
  }
`;

export const Disclaimer = styled.p`
  width: 100%;
  opacity: 0.6;
  font-size: 14px;
  text-align: center;
`;

export const SubmitButton = styled.input`
  font-size: 18px;
  width: 100%;
  height: 54px;
  margin: 0px 0;
  background: ${props => (props.customTheme ? props.customTheme.buttonBgColor : "#242525")};
  color: ${props => (props.customTheme ? props.customTheme.buttonTextColor : "#e6c897")};
  cursor: pointer;
  font-weight: 600;
  -webkit-appearance: none;
  border: 0;

  &:hover {
    background: ${props =>
      props.customTheme && props.customTheme.buttonBgColor
        ? lighten(0.0333, props.customTheme.buttonBgColor)
        : "#e6c897"};
    color: ${props => (props.customTheme ? props.customTheme.buttonTextColor : "#000")};
  }
`;

export const Explanations = styled.ul`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 18px;
  list-style: none;
  background: ${props => (props.theme === "light" ? "#fff" : "#242525")};
  color: ${props => (props.theme !== "light" ? "#fff" : "#242525")};
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
`;

export const Explanation = styled.li`
  display: block;
  margin-bottom: 18px;
`;

export const ExplanationTitle = styled.h4`
  margin: 0 0 9px 0;
`;

export const ExplanationContent = styled.p``;

export const ToggleConsents = styled.a`
  position: absolute;
  top: 18px;
  right: 18px;
  display: block;
  width: 20px;
  height: 20px;
  background: url();
  background-size: cover;
  background-repeat: no-repeat;
  z-index: 10;
  opacity: 0.5;
  cursor: pointer;

  &:hover {
    opacity: 1;
  }
`;

const PMLogo = styled.div`
  display: block;
  width: 42px;
  height: 42px;
  margin: 9px 0;

  svg {
    width: 100%;
    height: 100%;

    path {
      fill: ${props => props.customTheme && props.customTheme.formLogoColor ? props.customTheme.formLogoColor : "#fff"};
    }
  }

`;

const ConsentPolicyToggleContainer = styled.div`
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 15px 0;
`;

const ConsentPolicyToggle = styled.a`
  color: ${props => (props.customTheme ? props.customTheme.formLinkColor : "#999")};
  padding: 9px 0 9px 0;
  width: auto;

  &:hover {
    span {
      opacity: 1;
    }
  }
`;

const ConsentInfoIcon = styled.span`
  display: flex;
  width: 18px;
  height: 18px;
  margin-right: 9px;
  margin-top: -2px;
  background-size: cover;
  background-repeat: no-repeat;
  opacity: 0.25;

  svg {
    path: {
      fill: ${props => (props.customTheme ? rgba(props.customTheme.formTextColor, 0.5) : rgba("#666", 0.5))};
    }
  }
`;

const ExplanationsContainer = ({ consents, toggleConsents, theme }) => (
  <Explanations theme={theme}>
    <ToggleConsents onClick={() => toggleConsents()} />
    <h3>Personvernpolicy og cookies</h3>
  </Explanations>
);

export default class GDPRForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "",
      showExplanations: false,
      apiDataLoaded: false,
      apiData: null,
      success: false,
      formId: null,
      projectId: null,
      customTheme: null
    };
  }
  componentDidMount() {
    fetch(`${PRODUCTION ? "https://gdp.reeltime.no" : "https://localhost"}/api/leads/get_config`, {
      method: "POST",
      headers: {
        "content-type": "application/json",
        "x-rt-apikey": this.props.apiKey
      },
      cache: "no-cache",
      mode: "cors",
      body: JSON.stringify(this.state)
    })
      .then(res => res.json())
      .catch(err => console.log(err))
      .then(response => {
        console.log(response);
        this.setState({
          title: response.title,
          apiDataLoaded: true,
          apiData: response,
          formId: response.formId ? response.formId : null,
          projectId: response.projectId ? response.projectId : null,
          fields: response.fields,
          consents: response.consents,
          fieldValues: response.fields ? response.fields.reduce((obj, item) => ({ ...obj, [item.id]: "" }), {}) : {},
          fieldErrors: response.fields ? response.fields.reduce((obj, item) => ({ ...obj, [item.id]: "" }), {}) : {},
          consentValues: response.consents
            ? response.consents.reduce((obj, item) => ({ ...obj, [item.id]: false }), {})
            : {},
          customTheme: response.theme
        });
        console.log(this.state);
      });
  }
  handleChange(e) {
    this.setState(
      {
        fieldValues: {
          ...this.state.fieldValues,
          [e.target.name]: e.target.value
        },
        fieldErrors: {
          ...this.state.fieldErrors,
          [e.target.name]: false
        }
      },
      () => {
        if (this.props.onValueChange) {
          this.props.onValueChange({ [e.target.name]: e.target.value });
        }
      }
    );
  }
  handleCheckboxChange(e, id) {
    this.setState(
      {
        consentValues: {
          ...this.state.consentValues,
          [id]: !this.state.consentValues[id]
        }
      },
      () => {
        if (this.props.onValueChange) {
          this.props.onValueChange({ [consents[idx].id]: consents[idx].accepted });
        }
      }
    );
  }
  handleSubmit() {
    let errors = false;

    for (let [key, value] of Object.entries(this.state.fieldValues)) {
      const [field] = this.state.fields.filter(f => f.id === key);
      if (field && field.validation_rule) {
        for (let op of field.validation_rule.ops) {
          const rule = yup[field.validation_rule.type]()[op.op](
            op.rule === null ? value : op.op === "matches" ? new RegExp(op.rule, "gi") : op.rule
          );
          const status = rule.isValidSync(value);
          if (!status) {
            console.log(field, op, status);
            errors = true;
            this.setState({
              fieldErrors: {
                ...this.state.fieldErrors,
                [field.id]: true
              }
            });
          }
        }
      }
    }
    console.log(this.state);
    if (!errors) {
      fetch(`${PRODUCTION ? "https://gdp.reeltime.no" : "https://localhost"}/api/leads/handle_lead`, {
        method: "POST",
        headers: {
          "content-type": "application/json",
          "x-rt-apikey": this.props.apiKey
        },
        cache: "no-cache",
        mode: "cors",
        body: JSON.stringify(this.state)
      })
        .then(res => res.json())
        .catch(err => console.log(err))
        .then(response => {
          console.log(response);
          if (response.message && response.message === "LEAD RECEIVED") {
            this.setState(
              {
                success: true
              },
              () =>
                setTimeout(() => {
                  console.log(this.state);
                  this.setState({
                    success: false,
                    fieldValues: this.state.apiData.fields
                      ? this.state.apiData.fields.reduce((obj, item) => ({ ...obj, [item.id]: "" }), {})
                      : {},
                    fieldErrors: this.state.apiData.fields
                      ? this.state.apiData.fields.reduce((obj, item) => ({ ...obj, [item.id]: false }), {})
                      : {},
                    consentValues: this.state.apiData.consents
                      ? this.state.apiData.consents.reduce((obj, item) => ({ ...obj, [item.id]: false }), {})
                      : {}
                  });
                }, 2300)
            );
          }
          if (this.props.onSubmit) {
            this.props.onSubmit(this.state);
          }
        });
    }
  }

  render() {
    return (
      <FormContainer
        className="gdpr-form gdpr-form-container"
        apiDataLoaded={this.state.apiDataLoaded}
        theme={this.props.theme}
        customTheme={this.state.customTheme}
        onSubmit={e => {
          e.preventDefault();
          this.handleSubmit();
        }}
      >
        <Success success={this.state.success} />
        <Title customTheme={this.state.customTheme}>{this.state.title}</Title>
        {this.state.fields &&
          this.state.fields.sort(sortByProperty("order")).map((field, i) => {
            return field.component === "textarea" ? (
              <FormTextarea
                className="gdpr-form gdpr-form-input"
                key={field.id}
                id={field.id}
                name={field.id}
                placeholder={field.title}
                onInput={e => this.handleChange(e)}
                theme={this.props.theme}
                error={this.state.fieldErrors[field.id] === true}
                value={this.state.fieldValues[field.id]}
                isLastNotOdd={!isOdd(i) && i === this.state.fields.length - 1}
                customTheme={this.state.customTheme}
              />
            ) : (
              <FormInput
                className="gdpr-form gdpr-form-input"
                key={field.id}
                id={field.id}
                name={field.id}
                type={field.type}
                placeholder={field.title}
                onInput={e => this.handleChange(e)}
                theme={this.props.theme}
                error={this.state.fieldErrors[field.id] === true}
                value={this.state.fieldValues[field.id]}
                isLastNotOdd={!isOdd(i) && i === this.state.fields.length - 1}
                customTheme={this.state.customTheme}
              />
            );
          })}
        {this.state.consents && this.state.consents.length ? (
          <Consents theme={this.props.theme} customTheme={this.state.customTheme}>
            <ConsentsHeading>Jeg ønsker å motta...</ConsentsHeading>
            {this.state.consents.map(consent => (
              <Consent key={consent.id}>
                <ConsentLabel
                  onClick={e => {
                    e.preventDefault();
                    this.handleCheckboxChange(e, consent.id);
                  }}
                >
                  <ConsentInput
                    type="checkbox"
                    checked={this.state.consentValues[consent.id]}
                    theme={this.props.theme}
                    customTheme={this.state.customTheme}
                  />
                  <ConsentCheck
                    checked={this.state.consentValues[consent.id]}
                    theme={this.props.theme}
                    customTheme={this.state.customTheme}
                  />
                  {consent.explanation}
                </ConsentLabel>
              </Consent>
            ))}
          </Consents>
        ) : null}
        <SubmitButton
          className="gdpr-form gdpr-form-submit"
          customTheme={this.state.customTheme}
          type="submit"
          value={this.props.buttonText || "Send"}
        />
        <ConsentPolicyToggleContainer>
          <ConsentPolicyToggle
            customTheme={this.state.customTheme}
            className="gdpr-form gdpr-form-policylink"
            href="https://privatmegleren.no/personvern"
            target="_blank"
          >
            Personvernpolicy for PrivatMegleren
          </ConsentPolicyToggle>
          <PMLogo customTheme={this.state.customTheme} dangerouslySetInnerHTML={{__html: pmLogo }} />
        </ConsentPolicyToggleContainer>
        {this.state.disclaimer && <Disclaimer>{this.state.disclaimer}</Disclaimer>}
        {this.state.showExplanations && (
          <ExplanationsContainer
            theme={this.props.theme}
            consents={this.state.consents}
            toggleConsents={() => this.setState({ showExplanations: !this.state.showExplanations })}
          />
        )}
      </FormContainer>
    );
  }
}
