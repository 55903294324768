import "whatwg-fetch";
import "core-js/es6/array";
import "core-js/es7/array";
import "core-js/es6/object";
import "core-js/es7/object";
import "core-js/es6/promise";
import "core-js/es6/map";
import "core-js/es6/set";
import React from "react";
import { render } from "react-dom";
import GDPRForm from "./form";

export function Form({ options, onMount, onSubmit, onValueChange }) {
  let errors = [];
  if (options.target && !document.querySelectorAll(options.target).length) {
    //errors.push(new Error("Target selector missing."));
    throw new Error("Target selector is missing.");
  }
  if (!options.apiKey) {
    //errors.push(new Error("API key is missing."));
    throw new Error("API key is missing.");
  }
  if (
    options.customForm &&
    (!options.customForm.consents || (options.customForm.consents && !options.customForm.consents.length))
  ) {
    //errors.push(new Error("Consent definitions are missing."));
    throw new Error("Consent definitions are missing.");
  }
  render(
    <GDPRForm
      apiKey={options.apiKey}
      formType={options.formType || null}
      theme={options.theme || "default"}
      buttonText={options.buttonText || null}
      customForm={options.customForm || null}
      onSubmit={onSubmit}
      onValueChange={onValueChange}
    />,
    options.target ? document.querySelectorAll(options.target)[0] : document.body
  );
  if (onMount) {
    onMount(!errors.length ? null : errors);
  }
}
