import React, { Component } from "react";
import styled from "styled-components/dist/styled-components.browser.es.js";

const SuccessContainer = styled.div`
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #fff;
    z-index: 10;
    transition: opacity 375ms ease-in-out;
    opacity: ${props => props.success ? 1 : 0};
    pointer-events: ${props => props.success ? "all" : "none"};
`;

const Success = ({ success }) => <SuccessContainer success={success} className="gdpr-form gdpr-form-success">
    <h3>Takk for din henvendelse!</h3>
    <p>Vi vil ta kontakt så snart som mulig.</p>
</SuccessContainer>;

export default Success;